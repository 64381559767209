import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
/* MODULES */
import Authorisation from './modules/Authorisation';
import Error from './modules/Error';
import Product from './modules/Product';
import Profile from './modules/Profile';
import Shops from './modules/Shops';
/* END MODULES */

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: [
                'Authorisation',
                'Product',
                'Shops',
            ],
        }),
    ],
    modules: {
        Authorisation,
        Error,
        Profile,
        Product,
        Shops,
    },
});
