import {convertCamelToSnake} from '../../util/keyConverter.js';
import handle from './index';

async function get(url, params = {}) {
  return await handle.get(url, {
    params: params,
  });
}

async function getPaginated(url, page = 1, perPage = 10, search, sortBy, descending, params = {}) {
  if (search) {
    params.search = search;
  }
  if (sortBy) {
    params.sortBy = convertCamelToSnake(sortBy);
  }
  if (typeof descending !== 'undefined') {
    params.desc = descending ? '1' : '0';
  }

  return handle.get(url, {
    params: {
      page: page,
      perPage: perPage,
      ...params,
    },
  });
}

async function post(url, data = {}, params = {}) {
  return await handle.post(url, data, {
    params: params,
  });
}

async function put(url, data = {}, params = {}) {
  return handle.put(url, data, {
    params: params,
  });
}

function destroy(url, params = {}) {
  return handle.delete(url, {
    params: params,
  });
}

export {
  get,
  getPaginated,
  post,
  put,
  destroy,
};
