import {fetch as fetchProfile} from '../../api/endpoints/profile.js';

export default {
  namespaced: true,
  state: {
    profile: null,
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    },
  },
  getters: {
    isAdmin: state => {
      return state.profile ? state.profile.isAdmin : false;
    },
  },

  actions: {
    async fetchProfile(context, reload = false) {
      if (!reload && context.state.profile) {
        return;
      }
      const request = await fetchProfile();
      const profile = request.data.data;
      context.commit('setProfile', profile);
    },
    clearProfile(context) {
      if (context.state.profile) {
        context.commit('setProfile', null);
      }
    },
  },
};
