import router from '../../router';

export default {
  namespaced: true,
  state: {
    token: null,
  },
  mutations: {
    setAuthorisationToken(currentState, token) {
      currentState.token = token;
    },
  },
  actions: {
    logout() {
      this.commit('Authorisation/setAuthorisationToken', null);
      router.push({
        name: 'Login',
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
  },
};
