import AuthorisationGuard from '@/guards/AuthorisationGuard.js';
import UnAuthorisedGuard from '@/guards/UnAuthorisedGuard.js';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '',
    beforeEnter: UnAuthorisedGuard,
    component: () => import('@/templates/Authorisation'),
    children: [
      {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/LoginPage.vue'),
      },
      {
        path: '/auth/callback',
        name: 'auth.callback',
        component: () => import('@/pages/AuthorisationCallback'),
      },
    ],
  },
  {
    path: '/app',
    beforeEnter: AuthorisationGuard,
    component: () => import('@/templates/Default'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/DashboardPage.vue'),
      },
      {
        path: '/sale-general',
        name: 'SaleGeneral',
        component: () => import(/* webpackChunkName: "sale-general" */ '@/pages/SaleGeneral.vue'),
      },
      {
        path: '/sale-product/:productId/:product?/:countryId?/:country?',
        name: 'SaleProduct',
        component: () => import(/* webpackChunkName: "sale-product" */ '@/pages/SaleProduct.vue'),
      },
      {
        path: '/sale-country/:countryId/:country?',
        name: 'SaleCountry',
        component: () => import(/* webpackChunkName: "sale-country" */ '@/pages/SaleCountry.vue'),
      },
      {
        path: '/sale-customer/:customerId/:customer?',
        name: 'SaleCustomer',
        component: () => import(/* webpackChunkName: "sale-customer" */ '@/pages/SaleCustomer.vue'),
      },
      {
        path: '/shops/',
        name: 'ShopsGeneral',
        component: () => import(/* webpackChunkName: "shops" */ '@/pages/ShopsPage.vue'),
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
