import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import HighchartsVue from 'highcharts-vue'

Vue.use(Vuetify);
Vue.use(HighchartsVue)

export default new Vuetify(
    {
        icons: {
            iconfont: 'mdi',
        },
    });
