import { getShops } from '@/api/endpoints/shops.js';
import shouldRefresh from '@/helpers/shouldRefresh.js';
import dayjs from 'dayjs';

export default {
  namespaced: true,
  state: {
    shops: null,
    lastFetchDate: 0,
  },
  mutations: {
    setShops(currentState, updatedShops) {
      currentState.shops = updatedShops;
      currentState.lastFetchDate = new dayjs().unix();
    },
  },
  getters: {
    shops: state => state.shops || null,
  },
  actions: {
    async fetchAllShopsAsync(context) {
      if (this.isFetching) return;
      if (context.state.shops !== null && context.state.shops.length > 0 && shouldRefresh(context.state.lastFetchDate)) {
        return;
      }

      this.isFetching = true;

      try {
        const response = await getShops();
        context.commit('setShops', response.data);
      } finally {
        this.isFetching = false;
      }
    },
  },
};
