import { getAllProducts } from '@/api/endpoints/products.js';
import shouldRefresh from '@/helpers/shouldRefresh.js';
import dayjs from 'dayjs';

export default {
  namespaced: true,
  state: {
    products: null,
    lastFetchDate: 0,
  },
  mutations: {
    setProducts(currentState, updatedProducts) {
      currentState.products = updatedProducts;
      currentState.lastFetchDate = new dayjs().unix();
    },
  },
  getters: {
    products: state => state.products || null,
  },
  actions: {
    async fetchAllProductsAsync(context) {
      if (this.isFetching) return;
      if (context.state.products !== null && context.state.products.length > 0 && shouldRefresh(context.state.lastFetchDate)) {
        return;
      }

      this.isFetching = true;

      const response = await getAllProducts();
      context.commit('setProducts', response.data);

      this.isFetching = false;
    },
  },
};
